
import { defineComponent } from "vue";

import PHeader from "@/components/PHeader.vue";
import PMain from "@/components/PMain.vue";
import PNewspaper from "@/components/PNewspaper.vue";
import { useNewspaperPage } from "@/composable/useNewspaperPage";
import PFooter from "@/components/PFooter.vue";

export default defineComponent({
  name: "Newspaper",
  components: { PFooter, PNewspaper, PMain, PHeader },

  setup() {
    useNewspaperPage();
  }
});
